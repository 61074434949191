<template>
  <div class="login-box">
    <div
      class="flex_row"
      style="
        margin: auto;
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        justify-content: space-between;
        z-index: 999;
      ">
      <div
        class="p_in_20 bg_fff"
        v-loading="loading"
        style="
          height: 440px;
          width: 500px;
          box-shadow: rgb(100 100 100 / 50%) 0 0 20px 0;
          border-radius: 10px;
          padding: 70px;
        ">
        <div
          class="fs_24"
          style="margin: 0 0 40px 0">
          登录
        </div>
        <div class="account-box">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm">
            <el-form-item prop="username">
              <el-input
                placeholder="用户名"
                prefix-icon="el-icon-user"
                size="small"
                maxlength="20"
                v-model="ruleForm.username"
                @keydown.native.enter="submit('ruleForm')"></el-input>
            </el-form-item>
            <el-form-item prop="pas">
              <el-input
                size="small"
                v-model="ruleForm.pas"
                type="password"
                prefix-icon="el-icon-key"
                placeholder="请输入密码"
                @keydown.native.enter="submit('ruleForm')">
                <i
                  slot="suffix"
                  style="margin-top: 8px; font-size: 18px"
                  autocomplete="auto" />
              </el-input>
            </el-form-item>
          </el-form>
          <div
            class="btnBox"
            @keydown.enter="submit('ruleForm')">
            <el-button
              @click="submit('ruleForm')"
              class="btn">
              登&nbsp;&nbsp;录
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <img
      :src="bgImg"
      alt=""
      style="
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 6;
      " />
    <img
      :src="cloudImg"
      alt=""
      style="position: absolute; bottom: 0; width: 100%; z-index: 2" />
  </div>
</template>

<script>
import { refreshToken } from '../../api/mine';
import { loginByUsername_api } from '@/api/mine';
import { sessionSet } from '@/utils/local.js';
import md5 from 'js-md5';
import { UPDATE_USER_INFO } from '@/constants/mutation-types';
import { deptInfo } from '@/api/resourceCenter/school';
import jsCookie from 'js-cookie';
import cloudImg from 'assets/image/Mask group2.png';
import bgImg from 'assets/image/Mask group_1.png';
import { liveDetail_api } from '../../api/course/subscribe';

export default {
  name: 'login',
  data() {
    return {
      tenantId: '501654',
      ruleForm: {
        // username: "13612121212", // 用户名或手机号
        username: '', // 用户名或手机号 // 学生
        // username: "", // 用户名或手机号
        // password: "", // 密码或者验证码
        // username: "hyg0696", // 用户名或手机号
        // username: "dy8192", // 用户名或手机号
        pas: '', // 密码或者验证码
      },
      rules: {
        username: [
          {
            required: true,
            message: '请输入用户名/手机号',
            trigger: 'blur',
          },
        ],
        pas: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
      loading: false,
      roleJSON: {
        administrator: 'teacher',
        admin: 'teacher',
        teacher: 'teacher',
        districtUser: 'teacher',
        xiaoManangAdmin: 'teacher',
      },
      sceneJSON: {
        administrator: 'area',
        admin: 'area',
        districtUser: 'area',
      },
      cloudImg,
      bgImg,
    };
  },
  mounted() {
    let userInfosan = window.localStorage.getItem('userInfosan');
    if (userInfosan) {
      this.loginByThirdParty(JSON.parse(userInfosan));
    }
  },
  methods: {
    //登录成功后监测token过期处理
    checkToken(res) {
      //过期时间戳
      let loginTime = new Date().getTime() + res.expires_in * 1000;
      let timer = setInterval(() => {
        //当过期时间小于十分钟时刷新token
        if (loginTime - new Date().getTime() < 60 * 1000 * 10) {
          refreshToken(res.refresh_token, res.tenant_id).then((res) => {
            this.saveInfo(res);
            loginTime = new Date().getTime() + res.expires_in * 1000;
          });
        }
      }, 30000);
      sessionSet('timer', timer);
    },
    // // 用户名登录
    submit(formName) {
      if (this.isDisabled) return this.$message.warning('请勿频繁操作');
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.isDisabled = true;
          setTimeout(() => {
            this.isDisabled = false;
          }, 4000);
          this.loginByUsername();
        }
      });
    },
    async loginByUsername() {
      this.loading = true;

      let info = {
        account: this.ruleForm.username,
        password: this.ruleForm.pas.toString(),
        types: 1,
      };
      jsCookie.set('userInfoSan', JSON.stringify(info), {
        domain: '.wuhousmartedu.com',
      });

      await loginByUsername_api({
        tenantId: this.tenantId,
        username: this.ruleForm.username,
        password: md5(this.ruleForm.pas.toString()),
        scope: 'all',
        type: 'account',
        types: 1,
      })
        .then(async (res) => {
          if (res.error_code === 400) {
            return this.$message.error('用户名或密码不正确');
          }
          if (res.error) {
            return this.$message.error(res.error_description);
          }
          this.saveInfo(res);
          this.checkToken(res);
          await this.getDeptInfo(res.dept_id);

          this.$router.go(-1);
        })
        .catch(() => {});
      this.loading = false;
    },
    // 第三方登录
    loginByThirdParty(info) {
      info.types = 2;
      jsCookie.set('userInfoSan', JSON.stringify(info), {
        domain: '.wuhousmartedu.com',
      });

      this.loading = true;
      loginByUsername_api({
        tenantId: info.tenantId,
        username: info.account,
        password: info.password,
        scope: 'all',
        type: 'account',
        types: 2,
      }).then(async (res) => {
        let userInfo = res;
        if (res.error_code === 400)
          return this.$message.error('用户名或密码不正确');
        if (res.error) {
          return this.$message.error(res.error_description);
        }
        this.saveInfo(res);
        this.checkToken(res);
        await this.getDeptInfo(res.dept_id);

        this.loading = false;

        let courseInfo = JSON.parse(window.localStorage.getItem('courseId'));
        if (courseInfo) {
          if (courseInfo.isLive === 'false') {
            await this.$router.push({
              //path: '/courseDetail?courseId=' + courseInfo.courseId,
              path: '/Detail?courseId=' + courseInfo.courseId,
            });
          } else {
            await liveDetail_api({ id: courseInfo.courseId }).then((res) => {
              let data = res.data;
              // 不需要报名：1需要报名 0不需要
              if (data.isSubscribe == 0) {
                // 直播中
                if (data.beginState == 0) {
                  // 直播：1直播 0录播
                  if (data.isLubo == 1) {
                    // 是否是自己的直播课
                    if (data.updateUserId === userInfo.user_id) {
                      this.$router.push('/order?courseId=' + data.id);
                    } else {
                      this.$router.push(
                        '/liveStu/player?telecastId=' + data.id
                      );
                    }
                    // 录播
                  } else {
                    this.$router.push(
                      '/liveStu/playerLubo?telecastId=' + data.id
                    );
                  }
                  // 未开播
                } else {
                  this.$router.push('/order?courseId=' + data.id);
                }
                // 需要报名
              } else {
                // 直播中
                if (data.beginState == 0) {
                  // 是否已报名：>0已报名 <=0未报名
                  if (data.subscribeId > 0) {
                    // 直播
                    if (data.isLubo == 1) {
                      this.$router.push(
                        '/liveStu/player?telecastId=' + data.id
                      );
                      // 录播
                    } else {
                      this.$router.push(
                        '/liveStu/playerLubo?telecastId=' + data.id
                      );
                    }
                    // 未报名
                  } else {
                    this.$router.push('/order?courseId=' + data.id);
                  }
                } else {
                  this.$router.push('/order?courseId=' + data.id);
                }
              }
            });
          }
        } else {
          if (info.target === 'course') {
            if (this.roleJSON[res.role_name] === 'teacher') {
              await this.$router.push('/mineTeacher');
            } else {
              await this.$router.push('/mine');
            }
          } else {
            await this.$router.push({ path: '/resource' });
          }
        }

        window.localStorage.removeItem('userInfosan');
        window.localStorage.removeItem('courseId');
      });
    },
    saveInfo(info) {
      this.$store.commit(UPDATE_USER_INFO, {
        userId: info.user_id,
        userName: info.nick_name,
        userAvatar: info.avatar,
        deptId: info.dept_id,
        roleName: info.role_name,
      });
      let token = {
        access_token: info.access_token,
        refresh_token: info.refresh_token,
        token_type: info.token_type,
      };
      sessionSet('tk', token);
      sessionSet('userInfo', info);
      sessionSet('userId', info.user_id);
    },
    // 获取学校信息
    async getDeptInfo(id) {
      await deptInfo({ id }).then((res) => {
        sessionSet('deptInfo', res.data);
      });
    },
  },
};
</script>

<style scoped>
.login-box {
  height: 100vh;
  min-width: 1200px;
  /*background-color: rgba(46, 130, 166, 0.6)*/
  /*background-image: url("../../assets/image/Mask group_1.png");*/
  /*background-size: 100% 100%;*/
  position: relative;
}

.btn {
  background: #3888f7;
  color: #fff;
  outline: none;
  padding: 0.8em 5rem;
  box-sizing: border-box;
  border: 0;
  width: 100%;
  height: 48px;
  border-radius: 48px;
  font-size: 20px;
}

.btnBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

::deep .el-input--small .el-input__inner {
  background-color: #f3f5f9;
  height: 48px;
  border-radius: 48px;
}

::deep .el-input--prefix .el-input__inner {
  padding-left: 50px;
}

::deep .el-input__prefix {
  left: 25px;
  top: 1px;
}

::deep .el-input__inner {
  border: 0;
}
</style>
